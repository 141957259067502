<template>
  <div class="PageBuilder">
    <input type="hidden" name="sections" :value="toJson"/>
    <!-- <textarea cols="30" v-model="toJson"></textarea> -->
    <div v-for="section in orderedByPosition" :key="section.id">
      <SectionRow
          :section="section"
          :sectionCount="localSections.length"
          @update="updateSection"
          @remove="removeSection"
          @move-up="sectionUp"
          @move-down="sectionDown"
      />
    </div>
    <div>
      <a @click.prevent="addSection">
        <span class="glyphicon glyphicon-plus" aria-hidden="true"></span>
        Ajouter une section
      </a>
    </div>
  </div>
</template>

<script>
import {v4 as uuid} from "uuid";
import SectionRow from "./SectionRow";

const getInitalSection = () => ({
  id: uuid(),
  type: "standard",
  position: 0,
  fullWidth: false,
  pictureUrl: "",
  modules: [
    {
      id: uuid(),
      position: 0,
      type: "wysiwyg",
      content: "",
    },
  ],
});

export default {
  name: "PageBuilder",
  props: {
    sections: {
      type: Array,
      default: () => [getInitalSection()],
    },
  },
  data() {
    return {
      localSections: [],
    };
  },
  watch: {
    toJson: {
      handler() {
        this.$emit("update");
      },
      immediate: true,
    },
  },
  computed: {
    orderedByPosition() {
      return [...this.localSections].sort((a, b) => a.position - b.position);
    },
    toJson() {
      return JSON.stringify(this.localSections);
    },
  },
  methods: {
    addSection() {
      this.localSections.push({
        id: uuid(),
        type: "standard",
        pictureUrl: "",
        fullWidth: false,
        position: this.localSections.length,
        modules: [
          {
            position: 0,
            id: uuid(),
            type: "wysiwyg",
            content: "",
          },
        ],
      });
    },
    removeSection(id) {
      this.localSections = this.localSections
          .filter((section) => section.id !== id)
          .map((section, index) => ({...section, position: index}));
    },
    sectionUp(currentSection) {
      const siblingSection = this.localSections.find(
          (section) => section.position === currentSection.position - 1,
      );

      siblingSection.position = currentSection.position;
      currentSection.position--;
    },
    sectionDown(currentSection) {
      const siblingSection = this.localSections.find(
          (section) => section.position === currentSection.position + 1,
      );

      siblingSection.position = currentSection.position;
      currentSection.position++;
    },
    updateSection(id, value) {
      this.localSections = this.localSections.map((section) =>
          section.id === id ? value : section,
      );
    },
  },
  created() {
    this.localSections = [...this.sections];
  },
  components: {
    SectionRow,
  },
};
</script>
