<template>
  <div>
    <div class="Section" :class="'Section--' + localSection.type">
      <Module
          class="Section__module"
          v-for="module in orderedByPosition"
          :key="module.id"
          :module="module"
          :moduleCount="localSection.modules.length"
          @update="updateModule"
          @remove="removeModule"
          @move-left="moduleLeft"
          @move-right="moduleRight"
      />
      <div class="Section__actions">
        <mdb-tooltip trigger="hover" :options="{ placement: 'right' }">
          <span slot="tip">Déplacer la section</span>
          <a
              slot="reference"
              :disabled="localSection.position === 0"
              @click="$emit('move-up', localSection)"
          ><span class="glyphicon glyphicon-arrow-up"></span
          ></a>
        </mdb-tooltip>
        <mdb-tooltip trigger="hover" :options="{ placement: 'right' }">
          <span slot="tip">Ajouter un module</span>
          <a
              slot="reference"
              :disabled="localSection.modules.length > 2"
              @click="moduleType = 'wysiwyg'"
          ><span class="glyphicon glyphicon-th-large"></span
          ></a>
        </mdb-tooltip>
        <mdb-tooltip trigger="hover" :options="{ placement: 'right' }">
          <span slot="tip">Editer la section</span>
          <a slot="reference" @click="editSection"
          ><span class="glyphicon glyphicon-pencil"></span
          ></a>
        </mdb-tooltip>
        <mdb-tooltip trigger="hover" :options="{ placement: 'right' }">
          <span slot="tip">Supprimer la section</span>
          <a
              slot="reference"
              :disabled="!canTrash"
              @click="$emit('remove', localSection.id)"
          ><span class="glyphicon glyphicon-trash"></span
          ></a>
        </mdb-tooltip>
        <mdb-tooltip trigger="hover" :options="{ placement: 'right' }">
          <span slot="tip">Déplacer la section</span>
          <a
              slot="reference"
              @click="$emit('move-down', section)"
              :disabled="localSection.position === sectionCount - 1"
          ><span class="glyphicon glyphicon-arrow-down"></span
          ></a>
        </mdb-tooltip>
      </div>
    </div>

    <mdb-modal v-if="sectionType">
      <mdb-modal-header :close="false">
        <mdb-modal-title>Editer la section</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <div class="text-left mb-3">
          <input
              type="checkbox"
              v-model="sectionFullWidth"
              class="custom-control-input"
              id="sectionFullWidthCheck"
          />
          <label for="sectionFullWidthCheck">Affichage pleine largeur</label>
        </div>
        <select
            class="browser-default custom-select Section__select"
            v-model="sectionType"
        >
          <option
              v-for="type in sectionTypes"
              :value="type.value"
              :key="type.value"
          >
            {{ type.name }}
          </option>
        </select>
        <ImageUpload
            v-if="sectionType === 'picture'"
            v-model="sectionPicture"
        />
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn
            type="button"
            size="sm"
            color="secondary"
            @click.native="sectionType = null"
        >Annuler
        </mdb-btn
        >
        <mdb-btn
            type="button"
            size="sm"
            color="default"
            @click.native="updateSection"
        >Valider
        </mdb-btn
        >
      </mdb-modal-footer>
    </mdb-modal>

    <mdb-modal v-if="moduleType">
      <mdb-modal-header :close="false">
        <mdb-modal-title>Quel module ajouter ?</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <select
            name="type"
            class="browser-default custom-select"
            v-model="moduleType"
        >
          <option
              v-for="type in moduleTypes"
              :value="type.value"
              :key="type.value"
          >
            {{ type.name }}
          </option>
        </select>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn
            type="button"
            size="sm"
            color="secondary"
            @click.native="moduleType = null"
        >Annuler
        </mdb-btn
        >
        <mdb-btn
            type="button"
            size="sm"
            color="default"
            @click.native="addModule"
        >Ajouter
        </mdb-btn
        >
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import {
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbBtn,
  mdbTooltip,
} from "mdbvue";
import {v4 as uuid} from "uuid";
import ImageUpload from "./ImageUpload";
import Module from "./Module";
import sectionTypes from "./sectionTypes";
import moduleTypes from "./moduleTypes";

export default {
  emits: ["move-down", "move-up", "remove", "update"],
  props: {
    section: {
      type: Object,
      required: true,
    },
    sectionCount: {
      type: Number,
      required: true,
    },
  },
  data() {
    console.log("SectionRow#data");

    return {
      displayPopin: false,
      localSection: {},
      sectionType: null,
      sectionFullWidth: false,
      moduleType: null,
      sectionPicture: null,
      sectionTypes,
      moduleTypes,
    };
  },
  computed: {
    canTrash() {
      console.log("SectionRow#canTrash");

      return this.sectionCount > 1;
    },
    orderedByPosition() {
      console.log("SectionRow#orderedByPosition");
      const newPosition = [...this.localSection.modules].sort(
          (a, b) => a.position - b.position,
      );
      console.log({newPosition});
      return newPosition;
    },
  },
  watch: {
    section: {
      handler(section) {
        console.log("SectionRow#sectionhandler");

        this.localSection = section;
      },
      immediate: true,
    },
    localSection: {
      handler(localSection) {
        console.log("SectionRow#localSectionhandler");

        this.$emit("update", localSection.id, localSection);
      },
      deep: true,
    },
  },
  methods: {
    editSection() {
      console.log("SectionRow#editSection");

      this.sectionType = this.localSection.type;
      this.sectionFullWidth = this.localSection.fullWidth;
    },
    updateSection() {
      console.log("SectionRow#updateSection");

      this.localSection.type = this.sectionType;
      this.localSection.pictureUrl = this.sectionPicture;
      this.localSection.fullWidth = this.sectionFullWidth;
      this.sectionType = null;
    },
    addModule() {
      console.log("SectionRow#addModule");

      this.localSection.modules.push({
        id: uuid(),
        position: this.localSection.modules.length,
        type: this.moduleType,
        content: "",
      });

      this.moduleType = null;
    },
    removeModule(id) {
      console.log("SectionRow#removeModule");

      this.localSection.modules = this.localSection.modules
          .filter((module) => module.id !== id)
          .map((module, index) => ({...module, position: index}));
    },
    moduleLeft(currentModule) {
      console.log("SectionRow#moduleLeft");

      const siblingModule = this.localSection.modules.find(
          (module) => module.position === currentModule.position - 1,
      );

      siblingModule.position = currentModule.position;
      currentModule.position--;
    },
    moduleRight(currentModule) {
      console.log("SectionRow#moduleRight");

      const siblingModule = this.localSection.modules.find(
          (module) => module.position === currentModule.position + 1,
      );

      siblingModule.position = currentModule.position;
      currentModule.position++;
    },
    updateModule(id, value) {
      console.log("SectionRow#updateModule");

      this.localSection.modules = this.localSection.modules.map((module) =>
          module.id === id ? value : module,
      );
    },
  },
  created() {
    console.log("SectionRow#created");

    this.sectionPicture = this.section.pictureUrl;
  },
  components: {
    Module,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbBtn,
    ImageUpload,
    mdbTooltip,
  },
};
</script>
