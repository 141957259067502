<template>
  <div class="js-image-upload">
    <div v-if="value" class="image">
      <img :src="value" alt=""/>
    </div>

    <div class="input-group input-file">
      <div class="custom-file">
        <input
            type="file"
            ref="file"
            @change="onSelect"
            class="custom-file-input"
            id="inputGroupFile01"
            aria-describedby="inputGroupFileAddon01"
        />
        <label class="custom-file-label" for="inputGroupFile01">{{
            fileName
          }}</label>
      </div>
    </div>

    <mdb-btn
        :disabled="message !== ''"
        class="submit"
        type="button"
        color="default"
        size="sm"
        @click.native="submit"
    >Envoyer l'image
    </mdb-btn>

    <div class="message">{{ message }}</div>
  </div>
</template>

<script>
import {mdbBtn} from "mdbvue";

export default {
  name: "ImageUpload",
  props: {
    value: {
      type: String,
      default: "",
    },
    maxSize: {
      type: Number,
      default: 500,
    },
  },
  data() {
    return {
      file: "",
      message: "",
      fileName: "",
    };
  },
  methods: {
    onSelect() {
      this.message = "";

      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      const file = this.$refs.file.files[0];
      this.fileName = file.name;

      this.file = file;

      if (!allowedTypes.includes(file.type)) {
        this.message = "Ce type de fichier n'est pas autorisé";
      }

      if (file.size > this.maxSize * 1000) {
        this.message = `Votre fichier dépasse la taille maximale (${this.maxSize}kb)`;
      }
    },
    async submit() {
      const formData = new FormData();

      formData.append("file", this.file);

      try {
        const json = await fetch("/admin/uploads", {
          body: formData,
          method: "post",
        }).then((response) => response.json());

        this.message = "Uploaded!!";
        this.$emit("input", json.file_url);
      } catch (err) {
        console.log(err);
        this.message = err.response.data.error;
      }
    },
  },
  components: {
    mdbBtn,
  },
};
</script>
