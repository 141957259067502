<template>
  <div>
    <div class="Module">
      {{ moduleName }}
      <div class="Module__actions">
        <mdb-tooltip trigger="hover" :options="{ placement: 'down' }">
          <span slot="tip">Déplacer le module</span>
          <a
              slot="reference"
              :disabled="localModule.position === 0"
              @click="$emit('move-left', localModule)"
          ><span class="glyphicon glyphicon-arrow-left"></span
          ></a>
        </mdb-tooltip>
        <mdb-tooltip trigger="hover" :options="{ placement: 'down' }">
          <span slot="tip">Editer le contenu</span>
          <a
              slot="reference"
              v-if="localModule.type === 'wysiwyg'"
              @click="editContent"
              type="button"
          ><span class="glyphicon glyphicon-pencil"></span
          ></a>
        </mdb-tooltip>
        <mdb-tooltip trigger="hover" :options="{ placement: 'down' }">
          <span slot="tip">Supprimer le module</span>
          <a
              slot="reference"
              :disabled="!canTrash"
              @click="$emit('remove', localModule.id)"
          ><span class="glyphicon glyphicon-trash"></span
          ></a>
        </mdb-tooltip>
        <mdb-tooltip trigger="hover" :options="{ placement: 'down' }">
          <span slot="tip">Déplacer le module</span>
          <a
              slot="reference"
              :disabled="localModule.position === moduleCount - 1"
              @click="$emit('move-right', localModule)"
          ><span class="glyphicon glyphicon-arrow-right"></span
          ></a>
        </mdb-tooltip>
      </div>
    </div>

    <mdb-modal v-if="displayPopin" size="lg">
      <mdb-modal-header :close="false">
        <mdb-modal-title>Modifier le contenu:</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <editor
            v-model="localContent"
            @onInit="initEditor"
            apiKey="uln01kgof7x47ikj9m4wrso1tj8eamw7884hnycch1y3pbnl"
            :init="{
            language: 'fr_FR',
            height: 500,
            menubar: false,
            plugins: ['media code image lists link'],
            toolbar:
              'undo redo | formatselect | bold italic | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | code image media link',
            file_picker_types: 'media',
            file_picker_callback: filePickerCallback,
            images_upload_url: '/admin/uploads',
            images_upload_handler: imageUploadHandler,
            content_style: `body,h1,h2,h3,h4,h5,h6,p { font-family:'Roboto', sans-serif; }`,
            link_class_list: [
              { title: 'Aucune', value: '' },
              { title: 'Bouton', value: 'btn btn-secondary-dark' },
            ],
          }"
        />
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn
            type="button"
            color="secondary"
            size="sm"
            @click.native="displayPopin = false"
        >Retour
        </mdb-btn
        >
        <mdb-btn
            type="button"
            color="default"
            size="sm"
            @click.native="saveContent"
        >Appliquer
        </mdb-btn
        >
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import {
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbBtn,
  mdbTooltip,
} from "mdbvue";
import Editor from "@tinymce/tinymce-vue";
import moduleTypes from "./moduleTypes";

export default {
  emits: ["move-right", "move-left", "remove", "update"],
  props: {
    module: {
      type: Object,
      required: true,
    },
    moduleCount: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      localModule: {},
      displayPopin: false,
      localContent: null,
      editor: null,
    };
  },
  computed: {
    canTrash() {
      return this.moduleCount > 1;
    },
    moduleName() {
      return moduleTypes.find(
          (module) => module.value === this.localModule.type,
      ).name;
    },
  },
  watch: {
    module: {
      handler(module) {
        this.localModule = module;
      },
      immediate: true,
    },
    localModule: {
      handler(localModule) {
        this.$emit("update", localModule.id, localModule);
      },
      deep: true,
    },
  },
  methods: {
    editContent() {
      this.displayPopin = true;
      this.localContent = this.localModule.content;
    },
    saveContent() {
      this.localModule.content = this.localContent;
      this.displayPopin = false;
    },
    initEditor(evt, editor) {
      this.editor = editor;
      console.log(this.editor);
      console.log(Object.keys(editor));
      editor.focus();
    },
    imageUploadHandler(blobInfo, success, failure) {
      const formData = new FormData();

      formData.append("file", blobInfo.blob(), blobInfo.filename());

      fetch("/admin/uploads", {
        body: formData,
        method: "post",
      })
          .then((response) => response.json())
          .then((json) => success(json.file_url));
    },
    filePickerCallback(callback, value, meta) {
      const input = document.createElement("input");

      input.setAttribute("type", "file");
      input.setAttribute("accept", "video/* image/*");

      input.onchange = function () {
        const file = this.files[0];
        const formData = new FormData();

        formData.append("file", file);
        callback("Envoi en cours... Patientez...");
        fetch("/admin/uploads", {
          body: formData,
          method: "post",
        })
            .then((response) => response.json())
            .then((json) => callback(json.file_url));
      };

      input.click();
    },
  },
  components: {
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbBtn,
    Editor,
    mdbTooltip,
  },
};
</script>
